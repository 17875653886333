import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import logo from '../assets/logos/kripteus.png';
import kripteus from '../assets/icons/kripteus-felirat.png';
import close from '../assets/icons/close.png';
import arrow from '../assets/icons/popup-arrow.png';

const Popup = ({ open, setOpen }) => {
  const closeModal = () => {
    setOpen(false);
  };
  return (
    <Wrapper>
      {open ? (
        <motion.div
          className='thankyou__box overlay'
          initial={{ scale: 0, x: '-50%', y: '-50%' }}
          animate={{
            scale: 1,
            x: '0%',
            y: '0%'
          }}
          exit={{ scale: 0 }}
          transition={{
            duration: 0.5,
            times: 1
          }}>
          <div className='popup-container'>
            <div className='popup-logo-container'>
              <img src={logo} alt='kripteus' />
              <img src={kripteus} alt='kripteus' />
            </div>

            <div className='close-icon-container'>
              <img
                className='close-icon'
                src={close}
                alt='button icon'
                onClick={closeModal}
              />
            </div>
            <div className='popup-main-heading'>
              <p>
                <strong>Thank you</strong> for reaching out.
              </p>
            </div>
            <div className='popup-heading'>
              <p>One of our team members will get back to you soon.</p>
            </div>
            <div className='popup-paragraph'>
              <img src={arrow} alt='arrow' className='icon' />
              <p>
                Please note that in order to for the Lambda method to be
                effective, consultation is needed, of which the fee is 400 EUR.
              </p>
              <p>
                On the occasion of concluding a contract, this amount will be
                deducted from the total fee of the service which is 3% of the
                amount to be examined by Lambda, but a minimum of EUR 4.100.
              </p>
            </div>
            <div className='popup-footer'>
              <h3>Sounds good for you?</h3>
              <h4>Let's start today!</h4>
              <a
                className='btn btn-blue'
                href='https://calendly.com/glaser-anita/60min?month=2022-08'
                target='_blank'
                rel='noreferrer noopener'>
                BOOK A CONSULTATION
              </a>
            </div>
          </div>
        </motion.div>
      ) : (
        ''
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(18, 18, 18, 0.6);
    display: flex;
    align-items: center;
    z-index: 99999;
  }
  .popup-container {
    width: 45%;
    height: 95%;
    z-index: 9999;
    background: #fff;
    margin: auto;
    position: relative;
    padding: 2% 4% 4% 3%;
    color: var(--main-text-color);
    overflow: scroll;

    @media screen and (min-width: 769px) and (max-width: 1300px) {
      width: 55%;
      height: 90%;
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 6%;
    }

    .popup-logo-container {
      display: flex;
      align-items: center;

      img:nth-child(1) {
        height: 2.8rem;
      }

      img:nth-child(2) {
        margin-left: 1rem;
      }
    }

    .popup-main-heading {
      margin-top: 4rem;
      margin-bottom: 1rem;

      strong {
        font-size: 40px;
        font-weight: bold;

        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }

      @media screen and (max-width: 1300px) {
        margin-top: 2rem;

        @media (max-width: 768px) {
          margin-top: 1rem;
        }
      }
    }

    .popup-heading {
      font-size: 22px;
      font-weight: 300;
      line-height: 33px;
      margin-bottom: 4rem;

      @media screen and (max-width: 1300px) {
        margin-bottom: 1rem;
      }
    }

    .popup-paragraph {
      margin-bottom: 3rem;
      .icon {
        position: absolute;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        letter-spacing: 0.02em;
        position: relative;
        margin-left: 3rem;
        margin-bottom: 1rem;
        @media screen and (max-width: 1300px) {
          font-size: 14px;
        }
      }
      @media screen and (max-width: 1300px) {
        margin-bottom: 1rem;
      }
    }

    .popup-footer {
      h3 {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 2;
        @media screen and (max-width: 1300px) {
          font-size: 1rem;
        }
      }
      h4 {
        font-weight: 300;
        font-size: 1.5rem;
        @media screen and (max-width: 1300px) {
          font-size: 1rem;
        }
      }

      .btn {
        height: 40px;
        min-width: 230px;
        font-size: 14px;
        align-items: center;
        display: flex;
        margin-top: 3rem;
        text-decoration: none;

        @media (max-width: 768px) {
          margin: 1rem auto;
        }
      }
    }

    .close-icon {
      position: absolute;
      right: 2rem;
      top: 2rem;
      cursor: pointer;

      @media (max-width: 768px) {
        height: 25px;
      }
    }
  }
`;

export default Popup;
