import React from 'react'
import HeroSection from './HeroSection'
import WhatIsLambda from './WhatIsLambda'
import HowDoesItWork from './HowDoesItWork'
import CryptoTax from './CryptoTax'
import { SubjectSection, About, Contact } from './index'
import { Navbar } from '../components'

const Mainpage = () => {
  return (
    <main id='top'>
      <Navbar />
      <HeroSection />
      <WhatIsLambda />
      <HowDoesItWork />
      <SubjectSection />
      <CryptoTax />
      <About />
      <Contact />
    </main>
  )
}

export default Mainpage
