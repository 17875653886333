import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

// import images
import bg from '../assets/images/hero.png';
import arrow from '../assets/icons/dot-arrow.png';
import trans from '../assets/images/kripteus-transparent.png';

const HeroSection = () => {
  return (
    <Wrapper>
      <div className='bg-wrapper'>
        <div className='bg-container'>
          <img src={bg} alt='' />
        </div>
        <div className='bg2-container'>
          <img src={trans} alt='' />
        </div>
        <div className='title-container'>
          <div className='text-container'>
            <h1 className='bold'>
              Cashing out your crypto funds can be tricky
            </h1>
            <p>
              let us cover the documentation necessary for your transactions to
              go smoothly
            </p>
          </div>
          <div className='btn-container'>
            <Link
              className='btn btn-blue'
              to='contact'
              spy={true}
              hashSpy={true}
              offset={-160}>
              GET IN TOUCH
            </Link>
            <Link
              className='btn btn-transparent'
              to='what'
              spy={true}
              hashSpy={true}
              offset={-160}>
              SHOW MORE
            </Link>
          </div>
        </div>
      </div>
      <div>
        <div className='section-container'>
          <div className='arrow'>
            <img src={arrow} alt='dot arrow' />
          </div>
          <div className='subtitle'>
            <h2 className='light'>
              Traditional financial institutions still assume that
              cryptocurrencies impose a high money laundering risk, which
              restricts crypto owners from freely transferring their income into
              the banking system.
            </h2>
          </div>
        </div>
        <div className='greenline-content'>
          <h2>
            <span className='hard-bold'>Lambda </span>offers
            <span className='hard-bold'> a solution</span> to this problem for
            both parties
          </h2>
        </div>
      </div>
    </Wrapper>
  );
};

export default HeroSection;

const Wrapper = styled.div`
  width: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  .bg-wrapper {
    width: 100%;
    min-height: 82vh;
    margin-top: 130px;
    position: relative;

    .bg-container {
      position: absolute;
      width: 70%;
      height: auto;
      top: 30px;
      right: 0;

      @media screen and (max-width: 1024px) {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
      }

      img {
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: 1024px) {
        height: 40vh;
      }
    }

    .bg2-container {
      position: absolute;
      width: 28%;
      height: auto;
      top: 100px;
      right: 0;
      overflow: hidden;

      @media screen and (max-width: 1024px) {
        width: 40%;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .title-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 10vh;
    left: 7%;
    width: 52%;
    height: 60vh;
    background: rgba(217, 217, 217, 0.75);
    padding: 75px 40px 40px 48px;

    @media screen and (min-width: 1025px) and (max-width: 1440px) {
      height: 65vh;
    }

    @media screen and (max-width: 1024px) {
      position: relative;
      width: 100%;
      height: fit-content;
      top: 0;
      left: 0;
      margin-bottom: 3rem;
      padding: 20px;
    }

    h1 {
      font-size: var(--hero-font-size);
      font-family: var(--hero-font-family);
    }

    p {
      padding-block: 75px;
      font-size: 1.375rem;

      @media screen and (max-width: 1024px) {
        margin-bottom: 4rem;
      }

      @media screen and (max-width: 480px) {
        margin-bottom: 0;
      }
    }

    .btn-container {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 60px;
      bottom: 40px;
      right: 40px;

      @media screen and (min-width: 1025px) and (max-width: 1440px) {
        bottom: 20px;
      }

      a {
        text-decoration: none;
      }

      @media screen and (max-width: 480px) {
        flex-direction: column;
        justify-content: center;
        position: relative;
        gap: 30px;
        top: 0;
        right: 0;
      }

      .btn-blue {
        color: #ffffff;
        background-color: var(--main-btn-color);
        transition: all 0.3s ease;

        :hover {
          color: var(--main-btn-color);
          background-color: transparent;
          cursor: pointer;
        }
      }

      .btn-transparent {
        color: var(--main-btn-color);
        background-color: transparent;
        transition: all 0.3s ease;

        :hover {
          color: #ffffff;
          background-color: var(--main-btn-color);
          cursor: pointer;
        }
      }
    }
  }

  .section-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-inline: 7%;

    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .arrow-container {
      width: 10%;
    }

    .subtitle {
      display: flex;
      width: 90%;

      @media screen and (max-width: 480px) {
        width: 100%;
      }

      h2 {
        font-size: clamp(1.625rem, 1.025rem + 2vw, 3.5rem);
        transform: translateY(-15px);
        line-height: 84px;

        @media screen and (max-width: 480px) {
          line-height: 2;
          transform: translateY(0);
        }
      }
    }
  }

  .greenline-content {
    display: flex;
    width: 100%;
    padding-inline: 7%;
    margin-block: 125px;

    @media screen and (max-width: 480px) {
      margin-block: 60px;
    }

    h2 {
      font-size: clamp(1.625rem, 1.025rem + 2vw, 3.5rem);
      font-weight: 300;
      position: relative;

      ::before {
        position: absolute;
        content: '';
        top: -10px;
        left: 10%;
        width: 70%;
        height: 8px;
        background-color: var(--main-green);
      }

      ::after {
        position: absolute;
        content: '';
        bottom: -10px;
        right: 5%;
        width: 70%;
        height: 8px;
        background-color: var(--main-green);
      }
    }
  }
`;
