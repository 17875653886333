import React from 'react';
import styled from 'styled-components';

// import images
import arrow from '../assets/icons/dot-arrow.png';
import garrow from '../assets/icons/green-arrow.png';
import group from '../assets/images/group.png';
import logo from '../assets/images/kripteus-block.svg';

const About = () => {
  return (
    <SectionWrapper>
      <div className='content-wrapper' id='about'>
        <div className='title-wrapper'>
          <div className='arrow'>
            <img src={arrow} alt='dot arrow' />
          </div>
          <div className='title'>
            <h2>About us</h2>
          </div>
        </div>
        <div className='content-container'>
          <div className='top-content'>
            <div className='left-img'>
              <img className='logo' src={logo} alt='kripteus logo' />
            </div>
            <div className='textbox'>
              <p>
                In the interest of empowering individuals and organizations
                worldwide, Kripteus provides well-crafted and mindful services,
                consultation, and educational materials in pursuit of a renewed,
                safer, and more equitable financial world.
              </p>
              <p className='bold'>
                We develop technology that reduces the gaps between traditional
                and decentralized finance, implementing next-generation products
                and protocols to make financial transactions more equitable and
                available for all. Kripteus offers users more control over their
                money, as well as the resources necessary to stay compliant with
                the law - no matter where they are.
              </p>
            </div>
          </div>
          <div className='bottom-content'>
            <div className='textbox leftbox'>
              <p className='bold'>
                Kripteus is the leading service provider in the Hungarian crypto
                taxation market. The founders are all recognized professionals
                who have participated in the elaboration of the Hungarian tax
                legislation amendment for crypto assets, thus having the widest
                and most comprehensive professional knowledge about Hungarian
                crypto taxation.
              </p>
            </div>
            <div className='right-img'>
              <img src={group} alt='group' />
              <img src={garrow} alt='group' className='g-arrow' />
            </div>
          </div>
          <div className='content-footer'>
            <p>
              Our team consists of a blockchain and cryptocurrency expert with
              decades of public administration experience, a cryptocurrency
              taxation and tax regulation expert, a crypto and blockchain
              technology lawyer, a crypto economy and business and software
              development expert, and a crypto AML compliance expert.
            </p>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default About;

const SectionWrapper = styled.section`
  .content-wrapper {
    scroll-margin-top: 10rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 80px;
    @media screen and (max-width: 1359px) {
      margin-top: 0;
    }

    .title-wrapper {
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      .arrow {
        width: 10%;
      }

      .title {
        flex-direction: column;
        width: 90%;

        @media screen and (max-width: 10240px) {
          transform: translateY(10px);
          width: 100%;
        }

        @media screen and (max-width: 480px) {
          transform: translateY(0);
          width: 100%;
        }

        h2 {
          font-size: var(--title-font-size);
          font-family: var(--title-font-family);
          font-weight: 600;
          transform: translateY(-15px);

          @media screen and (max-width: 480px) {
            transform: translateY(15px);
          }
        }
      }
    }

    .content-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-inline: 10%;

      @media screen and (max-width: 1024px) {
        padding-inline: 0;
      }

      .top-content {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;
        gap: 4rem;
        margin-top: 3rem;

        @media screen and (max-width: 1024px) {
          flex-direction: column;
        }

        .left-img {
          width: 35%;
          @media screen and (max-width: 1024px) {
            width: 100%;
          }

          img.logo {
            width: 100%;
            height: 100%;
            transform: translateX(-1rem);

            @media screen and (max-width: 1024px) {
              transform: translateX(0);
            }
          }
        }

        .textbox {
          width: 80%;

          @media screen and (max-width: 1024px) {
            width: 100%;
          }

          p:first-child {
            margin-bottom: 1.5rem;
          }
        }
      }

      .bottom-content {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;
        gap: 4rem;
        margin-top: 4rem;

        @media screen and (max-width: 1024px) {
          flex-direction: column;
          gap: 2rem;
        }

        .leftbox {
          position: relative;
        }

        .right-img {
          position: relative;
          border-radius: 15px;
          display: flex;
          width: 70%;
          gap: 2rem;

          @media screen and (max-width: 1024px) {
            width: 100%;
          }

          img {
            width: 100%;
            height: auto;
          }

          .g-arrow {
            position: absolute;
            content: '';
            width: 50px;
            height: 50px;
            top: 30px;
            left: 36px;

            @media screen and (max-width: 480px) {
              width: 30px;
              height: 30px;
              top: 10px;
              left: 16px;
            }
          }
        }

        .textbox {
          width: 60%;

          @media screen and (max-width: 1024px) {
            width: 100%;
            order: 2;
          }
        }
      }

      .content-footer {
        width: 100%;
        margin-top: 4rem;

        @media screen and (max-width: 1024px) {
          margin-top: 2rem;
        }
      }
    }
  }

  p {
    font-size: var(--paragraph);
    line-height: 44px;

    @media screen and (max-width: 1366px) {
      line-height: 38px;
    }

    @media screen and (max-width: 1024px) {
      line-height: 1.6;
    }
  }
`;
