import React, { useState } from 'react'
import { GlobalContext } from './utils/GlobalContext'
import smoothscroll from 'smoothscroll-polyfill'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { Navbar, Footer } from './components'
import { Mainpage, Error, PrivacyPolicy } from './sections'

function App() {
  const [navClicked, setNavClicked] = useState(false)

  const clickValue = {
    navClicked,
    setNavClicked,
  }

  smoothscroll.polyfill()
  return (
    <>
      <Router>
        <GlobalContext.Provider value={clickValue}>
          <Navbar />
          <Routes>
            <Route path='/' element={<Mainpage />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='*' element={<Error />} />
          </Routes>
          <Footer />
        </GlobalContext.Provider>
      </Router>
    </>
  )
}

export default App
