import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Popup from './Popup';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
// import images
import arrow from '../assets/icons/dot-arrow.png';

const Contact = () => {
  const form = useRef();
  const refCaptcha = useRef();
  // const [checked, setChecked] = useState(false)
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  const sendEmail = e => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        result => {
          console.log(result.text);
          setMessage(
            'Thank you for reaching out! One of our team members will get back to you soon.'
          );
          setOpen(true);
        },
        error => {
          console.log('Hiba:' + error.text);
          setMessage('The message could not be sent!');
        }
      );
    e.target.reset();
  };

  return (
    <SectionWrapper>
      <div className='content-wrapper' id='contact'>
        <div className='title-wrapper'>
          <div className='arrow'>
            <img src={arrow} alt='dot arrow' />
          </div>
          <div className='title'>
            <h2>Contact</h2>
          </div>
        </div>
        <div className='content-container'>
          <p className='header-paragraph'>
            Trading on pioneering opportunities can be taxing...
            <br /> let us cover the resources necessary for your streams to stay
            smooth.
          </p>
          <div className='form-container'>
            <form ref={form} onSubmit={sendEmail}>
              <div className='input-block'>
                <label htmlFor='name'>
                  Name<sup>*</sup>
                </label>
                <input
                  type='text'
                  name='name'
                  id='name'
                  placeholder=''
                  required
                />
              </div>
              <div className='input-block'>
                <label htmlFor='email'>
                  Email<sup>*</sup>
                </label>
                <input
                  type='email'
                  name='email'
                  id='email'
                  placeholder=''
                  required
                />
              </div>
              <div className='input-block select'>
                <label htmlFor='issues'>What seems to be the issue?</label>
                <select name='issues' id='issues'>
                  <option value='none'>Choose one</option>
                  <option value='My bank transfer got stuck'>
                    My bank transfer got stuck
                  </option>
                  <option value='I want to transfer my crypto income to my bank account'>
                    I want to transfer my crypto income to my bank account
                  </option>
                  <option value='I am just planning ahead'>
                    I am just planning ahead
                  </option>
                </select>
              </div>
              <div className='input-block select'>
                <label htmlFor='problem'>How big of a problem is it?</label>
                <select name='values' id='values'>
                  <option value='none'>Choose one</option>
                  <option value='$50,000'>$50,000</option>
                  <option value='$50k - $100k'>$50k - $100k</option>
                  <option value='$100k - $300k'>$100k - $300k</option>
                  <option value='$300k - $700k'>$300k - $700k</option>
                  <option value='$700k <'>$700k &#60; </option>
                </select>
              </div>
              <div className='input-block select'>
                <label htmlFor='message'>
                  Give us a brief explanation of your situation:
                </label>
                <textarea
                  name='message'
                  id='message'
                  cols='30'
                  rows='10'></textarea>
              </div>
              <div className='btn-container'>
                <button type='submit' value='send' className='btn btn-blue'>
                  send
                </button>
                <div className='message'>{message}</div>
              </div>
              <ReCAPTCHA
                className='captcha'
                ref={refCaptcha}
                sitekey={'6LcfvKwhAAAAAEVVPs4lYRFeofd5tFjmXPiKx-WO'}
              />
            </form>
          </div>
          <p className='footer-paragraph'>
            Don't worry about getting spam,
            <br /> we only share relevant information and knowledge.
          </p>
        </div>
      </div>
      <div className='popup'>
        {message && <Popup open={open} setOpen={setOpen} />}
      </div>
    </SectionWrapper>
  );
};

export default Contact;
const SectionWrapper = styled.section`
  position: relative;

  .popup {
    position: absolute;
    left: 0%;
    top: 22%;
    z-index: 9999;
  }

  .content-wrapper {
    scroll-margin-top: 10rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 80px;

    .title-wrapper {
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      .arrow {
        width: 10%;
      }

      .title {
        flex-direction: column;
        width: 90%;

        @media screen and (max-width: 10240px) {
          transform: translateY(10px);
          width: 100%;
        }

        @media screen and (max-width: 480px) {
          transform: translateY(0);
          width: 100%;
        }

        h2 {
          font-size: var(--title-font-size);
          font-family: var(--title-font-family);
          font-weight: 600;
          transform: translateY(-15px);

          @media screen and (max-width: 480px) {
            transform: translateY(15px);
          }
        }
      }
    }

    .content-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-inline: 10%;

      @media screen and (max-width: 1024px) {
        padding-inline: 0;
      }

      .header-paragraph {
        margin-top: 4rem;
      }
      .footer-paragraph {
        margin-top: 2rem;
      }

      .header-paragraph,
      .footer-paragraph {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
      }

      .form-container {
        display: flex;
        flex-direction: column;
        width: 40%;

        @media screen and (max-width: 1024px) {
          width: 100%;
        }

        .input-block {
          display: flex;
          flex-direction: column;
          margin-block: 1rem;

          option {
            color: var(--main-text-color);
            font-style: normal;
          }

          input,
          select,
          textarea {
            border: none;
            border: 1px solid var(--main-text-color);
            border-radius: 10px;
            padding: 5px 8px;
          }

          textarea {
            margin-bottom: 1.5rem;
          }

          input,
          select {
            height: 2rem;
          }

          select {
            font-style: italic;
            color: #c4c4c4;
          }

          select,
          #email {
            width: 582px;
            max-width: 65%;

            @media screen and (max-width: 1024px) {
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  .btn-blue {
    color: #ffffff;
    background-color: var(--main-btn-color);
    transition: all 0.3s ease;
    font-family: var(--hero-font-family);

    :hover {
      color: var(--main-btn-color);
      background-color: transparent;
      cursor: pointer;
    }
  }

  p {
    font-size: var(--text-font-size);
    line-height: 44px;

    @media screen and (max-width: 1366px) {
      line-height: 38px;
    }

    @media screen and (max-width: 1024px) {
      line-height: 1.6;
    }
  }

  sup {
    color: red;
  }

  .captcha {
    margin-top: 2.5rem;
  }
`;
