import React from 'react';
import styled from 'styled-components';

// import images
import arrow from '../assets/icons/dot-arrow.png';
import flow from '../assets/images/flow.png';

const SubjectSection = () => {
  return (
    <SectionWrapper>
      <div className='content-wrapper'>
        <div className='title-container'>
          <div className='title-wrapper'>
            <div className='arrow'>
              <img src={arrow} alt='dot arrow' />
            </div>
            <div className='title'>
              <h2>The subject of the process</h2>
            </div>
          </div>
          <p>
            Typically, income from crypto assets is generated as a result of a
            complex process that includes many transfers, exchanges, and sales.
            Furthermore, the income generated is usually available in several
            forms: crypto exchanges, non-custodial software of hardware wallets,
            etc. The documentation must always be traced back to the starting
            fund or the capital, as this is the only way to verify the resulting
            income from the aspect of money laundering.
          </p>
        </div>
      </div>
      <div className='greenline-content'>
        <h2>
          Thus, <span>crypto assets</span> in any wallet must always
          <span> be traced back</span> to the starting fund. By the nature of
          the analysis, it is{' '}
          <span>not possible to review only a certain </span>
          amount within a portfolio.
        </h2>
      </div>
      <div className='image-container'>
        <img src={flow} alt='flow' />
      </div>
    </SectionWrapper>
  );
};

export default SubjectSection;

const SectionWrapper = styled.section`
  .content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5rem;

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;

      p {
        display: flex;
        width: 80%;
        margin: auto;
        margin-top: 2rem;

        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }

      .title-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;

        @media screen and (max-width: 1024px) {
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }

        @media screen and (max-width: 480px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }

        .arrow {
          display: flex;
          width: 10%;
        }

        .title {
          display: flex;
          flex-direction: column;
          width: 90%;

          @media screen and (max-width: 10240px) {
            transform: translateY(10px);
            width: 100%;
          }

          @media screen and (max-width: 480px) {
            transform: translateY(0);
            width: 100%;
          }

          h2 {
            font-size: var(--title-font-size);
            font-family: var(--title-font-family);
            font-weight: 600;
            transform: translateY(-15px);

            @media screen and (max-width: 480px) {
              transform: translateY(15px);
            }
          }

          p {
            font-size: var(--text-font-size);
            margin-top: 2rem;
            line-height: 44px;

            @media screen and (max-width: 480px) {
              line-height: 1.8;
            }
          }
        }
      }
    }
  }
  .greenline-content {
    display: flex;
    width: 100%;
    margin-top: 7%;

    @media screen and (max-width: 480px) {
      margin-block: 60px;
    }

    h2 {
      font-size: var(--medium-text-size);
      font-weight: 300;
      font-weight: 300;
      position: relative;
      text-align: justify;
      line-height: 80px;

      @media screen and (max-width: 480px) {
        line-height: 1.5;
      }

      span {
        font-weight: 700;
      }

      ::before {
        position: absolute;
        content: '';
        top: -10px;
        left: 10%;
        width: 70%;
        height: 8px;
        background-color: var(--main-green);
      }

      ::after {
        position: absolute;
        content: '';
        bottom: -10px;
        right: 5%;
        width: 70%;
        height: 8px;
        background-color: var(--main-green);
      }
    }
  }

  .image-container {
    margin: auto;
    margin-top: 10%;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }

    img {
      width: 90%;
      height: auto;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }
`;
