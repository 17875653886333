import React from 'react';
import styled from 'styled-components';

import Card from '../components/Card';
import fifteenIcon from '../assets/icons/15percent.svg';
import nineIcon from '../assets/icons/9percent.svg';

import arrow from '../assets/icons/dot-arrow.png';

const CryptoTax = () => {
  return (
    <Wrapper>
      <div>
        <div className='section-container' id='why'>
          <div className='arrow'>
            <img src={arrow} alt='dot arrow' />
          </div>
          <div className='subtitle'>
            <h1 className='semi-bold'>Crypto tax in Hungary</h1>
          </div>
        </div>
      </div>
      <div className='cards-container'>
        <div className='inner-cards-container'>
          <Card
            icon={fifteenIcon}
            text='Hungary has a favorable tax environment. As of 2021, according to the Hungarian Personal Income Tax Act, Hungarian individuals are obligated to pay 15% tax on income from acquired crypto assets on the basis of the so-called black box principle – meaning that tax is payable only after the crypto asset is exchanged to FIAT currency. if required, Kripteus can also provide assistance related to Hungarian taxation.'
            type='crypto'
          />
          <Card
            icon={nineIcon}
            text='Company-owned crypto assets are required to be recorded as receivables. Income from trading or mining crypto-assets can be offset against the costs. 9% of the resulting profit has to be paid as corporate tax. Moreover, by choosing the investment incentive tax method while avoiding the permanent withdrawal of capital, the corporation tax can be reduced to zero.'
            type='crypto'
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default CryptoTax;

const Wrapper = styled.div`
  width: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;

  .section-container {
    scroll-margin-top: 10rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-inline: 7%;

    @media screen and (max-width: 1359px) {
      flex-direction: column;
    }

    .arrow-container {
      width: 10%;
    }

    .subtitle {
      display: flex;
      flex-direction: column;
      width: 90%;
      @media screen and (max-width: 1359px) {
        margin-top: 2rem;
        width: 100%;
      }

      h1 {
        font-size: var(--title-font-size);
        font-family: var(--title-font-family);
        transform: translateY(-15px);
      }
      p {
        font-size: var(--small-parahraph);
      }
    }
  }

  .greenline-content {
    display: flex;
    width: 100%;
    padding-inline: 7%;
    margin-block: 5%;

    h2 {
      font-size: var(--medium-text-size);
      font-weight: 300;
      position: relative;

      ::before {
        position: absolute;
        content: '';
        top: -20px;
        left: 10%;
        width: 70%;
        height: 8px;
        background-color: var(--main-green);
      }

      ::after {
        position: absolute;
        content: '';
        bottom: -20px;
        right: 5%;
        width: 70%;
        height: 8px;
        background-color: var(--main-green);
      }
    }
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    padding-inline: 7%;

    @media screen and (min-width: 1360px) and (max-width: 1600px) {
      padding: 0;
    }

    .inner-cards-container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 5rem;
      @media screen and (max-width: 1359px) {
        flex-direction: column;
      }
      img {
        height: 35%;
      }
    }

    .arrow-container {
      width: 10%;
    }

    .subtitle {
      display: flex;
      flex-direction: column;
      width: 90%;

      h1 {
        font-size: var(--title-font-size);
        font-family: var(--title-font-family);
        transform: translateY(-15px);
      }
      p {
        font-size: var(--small-parahraph);
      }
    }
  }
`;
