// Import Logos, Images
import k from '../assets/icons/kripteus-logo-footer.png';
import kripteus from '../assets/icons/KRIPTEUS.svg';
import copyright from '../assets/icons/copyright.png';
import arrow from '../assets/icons/footer-arrow.png';

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <Wrapper>
      <footer>
        <section>
          <div className='logo'>
            <div className='logo-container'>
              <img src={k} alt='Kripteus logo' className='k' />
              <img src={kripteus} alt='Kripteus logo' className='kripteus' />
            </div>
            <div className='copyright'>
              <img src={copyright} alt='Copyright' />
              <p>All rights reserved</p>
            </div>
          </div>
          <div className='links'>
            <img src={arrow} alt='arrow' />
            <div className='links-title'>
              <h3>Legal</h3>
              <NavLink
                to='privacy-policy'
                onClick={() => window.scrollTo(0, 0)}>
                Privacy Policy
              </NavLink>
            </div>
          </div>
          <div className='newsletter'>
            <img src={arrow} alt='arrow' />
            <div className='links-title'>
              <h3>Newsletter</h3>
            </div>
            <div className='newsletter-form'>
              <form
                target='_self'
                method='post'
                action='https://kriptoadozas.hu/wp-admin/admin-post.php?action=mailpoet_subscription_form'
                className='mailpoet_form mailpoet_form_form mailpoet_form_widget mailpoet_form_tight_container'
                noValidate=''
                data-delay=''
                data-exit-intent-enabled=''
                data-font-family=''
                data-cookie-expiration-time=''>
                <input type='hidden' name='data[form_id]' defaultValue='3' />
                <input type='hidden' name='token' defaultValue='df74177a22' />
                <input type='hidden' name='api_version' defaultValue='v1' />
                <input
                  type='hidden'
                  name='endpoint'
                  defaultValue='subscribers'
                />
                <input
                  type='hidden'
                  name='mailpoet_method'
                  defaultValue='subscribe'
                />

                <label className='mailpoet_hp_email_label'>
                  Please leave this field empty
                  <input type='email' name='data[email]' />
                </label>
                <div className='mailpoet_spacer'></div>
                <div className='mailpoet_paragraph nl-footer-control form-item'>
                  <label htmlFor='name'>Name *</label>
                  <input
                    type='text'
                    autoComplete='on'
                    className='mailpoet_text'
                    name='data[form_field_YjQ3Nzc2NGYzZDJkX2ZpcnN0X25hbWU=]'
                    title='Name'
                    defaultValue=''
                    data-automation-id='form_first_name'
                    data-parsley-names='["Please specify a valid name.","Addresses in names are not permitted, please add your name instead."]'
                  />
                </div>
                <div className='mailpoet_paragraph nl-footer-control form-item'>
                  <label htmlFor='email'>Email *</label>
                  <input
                    type='email'
                    autoComplete='email'
                    className='mailpoet_text'
                    name='data[form_field_MTM0NGU2NjM3ZjYxX2VtYWls]'
                    title='E-mail'
                    defaultValue=''
                    data-automation-id='form_email'
                    data-parsley-required='true'
                    data-parsley-minlength='6'
                    data-parsley-maxlength='150'
                    data-parsley-error-message='Please specify a valid email address.'
                    data-parsley-required-message='This field is required.'
                  />
                </div>
                <div className='mailpoet_paragraph newsletter-button nl-footer-control'>
                  <button
                    className='mailpoet btn btn-blue'
                    data-automation-id='subscribe-submit-button'>
                    JOIN THE PROS
                  </button>

                  <span className='mailpoet_form_loading'>
                    <span className='mailpoet_bounce1'></span>
                    <span className='mailpoet_bounce2'></span>
                    <span className='mailpoet_bounce3'></span>
                  </span>
                </div>

                <div className='mailpoet_form_columns mailpoet_paragraph mailpoet_stack_on_mobile last'>
                  <div className='mailpoet_form_column'></div>
                </div>
              </form>
              <p>
                Don't worry about getting spam, we only share relevant
                information and knowledge.
              </p>
            </div>
          </div>
          <div className='copyright-mobile'>
            <img src={copyright} alt='Copyright' />
            <p>All rights reserved</p>
          </div>
        </section>
      </footer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 150px;
  footer {
    background-color: #0a0140;
    min-height: 550px;
    color: white;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1300px) {
      padding-bottom: 2rem;
    }

    section {
      display: flex;
      min-height: 550px;
      justify-content: space-between;
      margin-top: 3.5rem;

      @media screen and (max-width: 1300px) {
        flex-direction: column;
      }

      .logo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .logo-container {
          display: flex;
          align-items: center;

          @media screen and (max-width: 1300px) {
            margin-bottom: 2rem;
          }

          .k {
            height: 3.5rem;

            @media screen and (min-width: 1300px) and (max-width: 1600px) {
              height: 2rem;
            }
          }
          .kripteus {
            height: 2rem;
            width: 160px;
            margin-left: 2.4rem;

            @media screen and (min-width: 1300px) and (max-width: 1600px) {
              height: 1rem;
              margin-left: 1.5rem;
            }

            @media screen and (max-width: 1300px) {
              height: 1.2rem;
              margin-left: 1.5rem;
            }
          }
        }
      }

      .copyright-mobile {
        display: flex;
        margin: 1rem 0;
        align-items: center;

        img {
          height: 1rem;
          width: 1rem;
          margin-right: 1rem;
        }

        @media screen and (min-width: 1300px) {
          display: none;
        }
      }

      .copyright {
        display: flex;
        margin-bottom: 6.25rem;
        align-items: center;

        @media screen and (max-width: 1300px) {
          display: none;
        }

        img {
          height: 1rem;
          width: 1rem;
        }

        p {
          margin-left: 1.75rem;
        }
      }

      .links {
        display: flex;
        flex-direction: column;
        img {
          position: absolute;
          height: 1.6rem;
          width: 1.6rem;
          margin-left: -3.125rem;
          margin-top: 1rem;

          @media screen and (min-width: 1300px) and (max-width: 1600px) {
            margin-top: 0;
          }

          @media screen and (max-width: 1300px) {
            margin-left: 0;
          }
        }

        .links-title {
          display: flex;
          flex-direction: column;
          position: relative;

          h3 {
            font-family: var(--title-font-family);
            font-size: var(--paragraph);
            text-transform: uppercase;
            margin-bottom: 2.5rem;
            margin-top: 1rem;

            @media screen and (min-width: 1300px) and (max-width: 1600px) {
              margin-top: 0.3rem;
            }

            @media screen and (max-width: 1300px) {
              margin-left: 3.25rem;
            }
          }
        }
        a {
          text-decoration: none;
          color: white;
          font-size: 1rem;
          line-height: 1.6rem;

          &:hover {
            color: #ddd;
          }
        }
      }

      .newsletter {
        display: flex;
        flex-direction: column;

        .newsletter-form p {
          width: 55%;

          @media screen and (max-width: 1300px) {
            width: 100%;
          }
        }

        img {
          position: absolute;
          height: 1.6rem;
          width: 1.6rem;
          margin-left: -3.125rem;
          margin-top: 1rem;

          @media screen and (min-width: 1300px) and (max-width: 1600px) {
            margin-top: 0;
          }

          @media screen and (max-width: 1300px) {
            margin-left: 0;
            margin-top: 2rem;
          }
        }

        .links-title {
          display: flex;
          flex-direction: column;
          position: relative;

          h3 {
            font-family: var(--title-font-family);
            font-size: var(--paragraph);
            text-transform: uppercase;
            margin-bottom: 2.5rem;
            margin-top: 1rem;

            @media screen and (min-width: 1300px) and (max-width: 1600px) {
              margin-top: 0.3rem;
            }

            @media screen and (max-width: 1300px) {
              margin-top: 2rem;
              margin-left: 3.25rem;
            }
          }
        }

        .form-item {
          display: flex;
          flex-direction: column;

          label {
            font-size: var(--small-paragraph-size);
          }

          input {
            height: 2.5rem;
            max-width: 450px;
            border-radius: 10px;
            margin-top: 0.7rem;
            padding-left: 1rem;
            margin-bottom: 1.6rem;

            @media screen and (max-width: 768px) {
              max-width: 300px;
            }
          }
        }

        .btn-blue {
          color: #ffffff;
          background-color: var(--main-btn-color);
          transition: all 0.3s ease;
          margin-top: 3rem;
          margin-bottom: 2.5rem;
          font-family: var(--hero-font-family);

          :hover {
            color: var(--main-btn-color);
            background-color: transparent;
            cursor: pointer;
          }

          @media screen and (max-width: 1300px) {
            margin-top: 2rem;
          }
        }
      }
    }

    .mailpoet_hp_email_label {
      display: none;
    }
  }
`;

export default Footer;
