import React from "react";
import styled from "styled-components";

const Card = ({ icon, text, type }) => {
  return (
    <Wrapper className="single-card">
      <div className="single-card-inner-container">
        <div className="single-card-data-container">
          <div
            className={
              "single-card-image-container " +
              (type === "crypto" ? "crypto" : "")
            }
          >
            <div
              className={
                "inner-image-container " + (type === "crypto" ? "crypto" : "")
              }
            >
              <img src={icon} alt={text} />
            </div>
          </div>
          <div className="single-card-text-container">
            <p className="card-description">{text}</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  &.single-card {
    display: flex;
    justify-content: center;
    width: calc(100% / 3);
    @media screen and (max-width: 1359px) {
      width: 100%;
    }

    .single-card-inner-container {
      position: relative;
      width: 70%;
      margin: 4rem;
      border: 5px solid var(--main-btn-color);
      border-radius: 15px;
      transition: all 0.2s;

      @media screen and (max-width: 1359px) {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 4rem;
        width: 100%;
      }

      :hover {
        border: 5px solid var(--main-green);
      }

      .single-card-data-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem 2rem 2rem 2rem;
        .single-card-image-container {
          display: flex;
          justify-content: center;
          transform: translateY(-6rem);
          &.crypto {
            transform: translateY(-8rem);
          }
          .inner-image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2rem;
            border: 5px solid var(--main-green);
            border-radius: 100%;
            background-color: var(--main-bg);
            width: 136px;
            height: 136px;
            &.crypto {
              width: 200px;
              height: 200px;
            }
          }
        }
        .single-card-text-container {
          margin-top: -4rem;
        }
        .card-description {
          font-size: var(--small-parahraph);
        }
      }
    }
  }
`;

export default Card;
