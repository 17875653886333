import styled from 'styled-components'
import React, { useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { GlobalContext } from '../utils/GlobalContext'
import { Link } from 'react-scroll'

// Import Logos, Images
import logo from '../assets/logos/kripteus.png'
import kripteus from '../assets/logos/kripteus_navbar.png'

const Navbar = () => {
  const location = useLocation()
  const [clicked, setClicked] = useState(false)
  const { setNavClicked } = useContext(GlobalContext)

  const isMain = location.pathname === '/'

  function navClickHandle() {
    setNavClicked(true)
  }

  return (
    <Wrapper id='nav'>
      <div className='desktop-menu-wrapper'>
        <a href='#top'>
          <span className='logo' onClick={navClickHandle}>
            <img src={logo} alt='Kripteus' />
            <img src={kripteus} alt='Kripteus' className='logotext' />
          </span>
        </a>
        <div className='desktop-menu'>
          {isMain ? (
            <>
              <Link
                activeClass='active'
                to='what'
                spy={true}
                hashSpy={true}
                offset={-160}>
                What
              </Link>
              <Link
                activeClass='active'
                to='how'
                spy={true}
                hashSpy={true}
                offset={-160}
                duration={500}>
                How
              </Link>
              <Link
                activeClass='active'
                to='why'
                spy={true}
                hashSpy={true}
                offset={-160}>
                Why
              </Link>
              <Link
                activeClass='active'
                to='about'
                spy={true}
                hashSpy={true}
                offset={-160}>
                About
              </Link>
              <Link
                activeClass='contact-active'
                to='contact'
                spy={true}
                hashSpy={true}
                offset={-160}>
                <button className='btn btn-blue contact'>contact us</button>
              </Link>
            </>
          ) : (
            <>
              <a href='/#what'>What</a>
              <a href='/#how'>How</a>

              <a href='/#why'>Why</a>
              <a href='/#about'>About</a>
              <a href='contact'>
                <button className='btn btn-blue contact'>contact us</button>
              </a>
            </>
          )}
        </div>
      </div>

      <div className='mobile-wrapper'>
        <a href='#top'>
          <span className='logo' onClick={navClickHandle}>
            <img src={logo} alt='Kripteus logo' />
            KRIPTEUS
          </span>
        </a>
        <div className='mobile-nav'>
          <div className='hamburger-ul' onClick={() => setClicked(!clicked)}>
            <div className={`hamburger-1 ${clicked ? 'close-1' : ''}`}></div>
            <div className={`hamburger-2 ${clicked ? 'close-2' : ''}`}></div>
            <div className={`hamburger-3 ${clicked ? 'close-3' : ''}`}></div>
          </div>
          <div className={`mobile-menu ${clicked ? 'menu-active' : ''}`}>
            <div className='nav-items'>
              {isMain ? (
                <>
                  <Link
                    to='what'
                    activeClass='active'
                    spy={true}
                    hashSpy={true}
                    offset={-160}>
                    <span
                      className='mobile-menuitem'
                      onClick={() => setClicked(false)}>
                      What
                    </span>
                  </Link>
                  <Link
                    to='how'
                    activeClass='active'
                    spy={true}
                    hashSpy={true}
                    offset={-160}>
                    <span
                      className='mobile-menuitem'
                      onClick={() => setClicked(false)}>
                      How
                    </span>
                  </Link>
                  <Link
                    to='why'
                    activeClass='active'
                    spy={true}
                    hashSpy={true}
                    offset={-160}>
                    <span
                      className='mobile-menuitem'
                      onClick={() => setClicked(false)}>
                      Why
                    </span>
                  </Link>
                  <Link
                    to='about'
                    activeClass='active'
                    spy={true}
                    hashSpy={true}
                    offset={-160}>
                    <span
                      className='mobile-menuitem'
                      onClick={() => setClicked(false)}>
                      About us
                    </span>
                  </Link>
                  <Link
                    to='contact'
                    activeClass='active'
                    spy={true}
                    hashSpy={true}
                    offset={-160}>
                    <span
                      className='mobile-menuitem'
                      onClick={() => setClicked(false)}>
                      contact us
                    </span>
                  </Link>
                  <hr />
                </>
              ) : (
                <>
                  <a href='/#what'>
                    <span
                      className='mobile-menuitem'
                      onClick={() => setClicked(false)}>
                      What
                    </span>
                  </a>
                  <a href='/#how'>
                    <span
                      className='mobile-menuitem'
                      onClick={() => setClicked(false)}>
                      How
                    </span>
                  </a>

                  <a href='/#why'>
                    <span
                      className='mobile-menuitem'
                      onClick={() => setClicked(false)}>
                      Why
                    </span>
                  </a>
                  <a href='/#about'>
                    <span
                      className='mobile-menuitem'
                      onClick={() => setClicked(false)}>
                      About
                    </span>
                  </a>
                  <a href='contact'>
                    <span
                      className='mobile-menuitem'
                      onClick={() => setClicked(false)}>
                      contact us
                    </span>
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 999;
  height: 130px;
  background-color: #ffffff;
  font-size: clamp(1.25rem, 1.17rem + 0.2667vw, 1.5rem);

  .desktop-menu-wrapper {
    display: flex;
    width: 86%;
    height: 100%;
    flex-direction: row;
    list-style: none;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-family: var(--title-font-family);
  }

  .logo img {
    max-height: 91px;

    @media screen and (max-width: 1024px) {
      margin-right: 37px;
    }
  }

  .logotext {
    margin-left: 37px;
  }

  .desktop-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2.3rem;
    cursor: pointer;

    a {
      font-size: clamp(1.25rem, 1.17rem + 0.2667vw, 1.5rem);
      font-family: var(--hero-font-family);

      button {
        font-size: clamp(1.25rem, 1.17rem + 0.2667vw, 1.5rem);
      }
    }

    .contact {
      background-color: var(--main-btn-color);
      color: #ffffff !important;
      padding: 15px 25px;
      border-radius: 10px;
      text-transform: uppercase;

      :hover {
        color: #ffffff;
      }
    }

    span {
      position: relative;
      cursor: pointer;
      text-decoration: none;
      list-style: none;
      position: relative;
      transition: all 0.3s ease;
      font-weight: 600;
      font-size: clamp(1.25rem, 1.17rem + 0.2667vw, 1.5rem);
      font-family: var(--hero-font-family);

      :hover {
        color: var(--main-btn-color);
      }

      ::after {
        position: absolute;
        right: -1rem;
        top: 0;
        content: '';
        width: 2px;
        height: 100%;
        background-color: var(--secondary-bg);
      }
    }

    .active {
      position: relative;
      transition: all 0.3s ease-in-out;
      color: var(--main-btn-color);

      ::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--main-btn-color);
      }
    }

    .selected {
      position: relative;
      transition: all 0.3s ease-in-out;
      color: red;
    }

    a {
      display: flex;
      justify-content: center;
      color: var(--secondary-bg);
    }
  }

  .mobile-wrapper {
    display: none;
  }

  @media (max-width: 1024px) {
    .mobile-wrapper {
      display: flex;
      width: 100%;
      height: 130px;
      align-items: center;
      justify-content: space-between;
      padding-inline: 5%;
    }

    .hamburger-ul {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-evenly;
      width: 32px;
      height: 35px;
      cursor: pointer;
    }

    .hamburger-1,
    .hamburger-2,
    .hamburger-3 {
      width: 30px;
      height: 5px;
      transition: all 0.3s ease-in-out;
      background-color: var(--main-text-color);
    }

    .close-1 {
      transform: rotate(45deg) translateY(8px);
    }
    .close-3 {
      transform: rotate(-45deg) translateY(-9px) translateX(0.5px);
    }
    .close-2 {
      display: none;
    }

    .mobile-menu {
      position: absolute;
      top: 130px;
      right: 0;
      display: flex;
      flex-direction: column;
      background-color: var(--main-bg);
      width: 100%;
      height: calc(100vh - 130px);
      align-items: center;
      justify-content: space-evenly;
      transform: translateX(100%);
      transition: all 0.3s ease-in-out;
      padding: 2rem 10%;
      list-style: none;

      .nav-items {
        display: flex;
        flex-direction: column;
        height: 50vh;
        align-items: center;
        justify-content: space-between;
        text-transform: capitalize;
      }
    }

    hr {
      margin: 10% 5%;
      border-radius: 0;
      border: none;
      height: 2px;
      width: 90vw;
    }

    .header-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 15%;
      background-color: var(--main-bg);

      p {
        color: var(--main-text-color);
      }

      img {
        padding-right: 0.5rem;
      }
      span {
        font-weight: 700;
      }
    }

    .menu-active {
      transform: translateX(0);
    }

    .mobile-menuitem {
      font-weight: 700;
      position: relative;
      width: 100%;
      transition: all 0.3s ease-in-out;
      text-decoration: none;
      font-size: 1.5rem;
      color: var(--primary-bg);
    }
  }
  button.language-switcher {
    border: none;
    font-size: var(--paragraph-font-size);
    background-color: var(--primary-bg);
    padding: 5px 15px;
    border-radius: 25px;
    transition: all 0.3s ease;
    font-family: var(--text-font-style);

    :hover {
      cursor: pointer;
      color: var(--banner-color);
    }
    :active {
      transform: rotate(360deg);
    }

    @media screen and (max-width: 1024px) {
      padding: 15px;
      border-radius: 50%;
    }
  }
`

export default Navbar
