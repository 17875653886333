import React from 'react';
import styled from 'styled-components';

import Card from '../components/Card';
import magnifyIcon from '../assets/icons/magnify-icon.svg';
import talkIcon from '../assets/icons/talk-icon.svg';
import reportIcon from '../assets/icons/report-icon.svg';

import arrow from '../assets/icons/dot-arrow.png';

const WhatIsLambda = () => {
  return (
    <Wrapper>
      <div>
        <div className='section-container' id='what'>
          <div className='arrow'>
            <img src={arrow} alt='dot arrow' />
          </div>
          <div className='subtitle'>
            <h1 className='semi-bold'>What is Lambda?</h1>
            <p>
              The crypto commerce world assigns a starring role to regulatory
              compliance. Cryptocurrency transactions have to comply with
              applicable regulations, especially as blockchain technology
              continues to complement traditional financial systems. Current
              regulations require regulated institutions to follow anti-money
              laundering (AML) protocols, which are designed to stop malevolent
              actors from concealing the source of illegally obtained money.
            </p>
          </div>
        </div>
        <div className='greenline-content'>
          <h2>
            <span className='bold'>The Lambda</span> method was developed{' '}
            <span className='bold'>to verify</span> the source of{' '}
            <span className='bold'>crypto income</span> and the{' '}
            <span className='bold'>source of funds</span> to banks, agencies,
            and other regulated institutions in order{' '}
            <span className='bold'>to prove</span> that the crypto owner and
            their crypto income are{' '}
            <span className='bold'>transparent, deductible</span>, and do{' '}
            <span className='bold'>not carry any money laundering risks</span>.
          </h2>
        </div>
      </div>
      <div className='cards-container'>
        <div className='inner-cards-container'>
          <Card
            icon={magnifyIcon}
            text='The Lambda method was developed in accordance with the AML regulations of the European Union to comply with international standards.'
          />
          <Card
            icon={talkIcon}
            text='The analysis is performed by compliance and AML experts, tax advisors, and attorneys specializing in crypto assets in order to review the crypto income of our clients based on every possible criterion. '
          />
          <Card
            icon={reportIcon}
            text='The report includes the examination of the first or initial source of funds, and reviewing, explaining, and processing of transactions in the world of crypto.'
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default WhatIsLambda;

const Wrapper = styled.div`
  width: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;

  @media screen and (max-width: 1359px) {
    padding-top: 2rem;
  }

  .section-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-inline: 7%;

    @media screen and (max-width: 1359px) {
      flex-direction: column;
    }

    .arrow-container {
      width: 10%;
    }

    .subtitle {
      display: flex;
      flex-direction: column;
      width: 90%;

      @media screen and (max-width: 1359px) {
        margin-top: 2rem;
        width: 100%;
      }

      h1 {
        font-size: var(--title-font-size);
        font-family: var(--title-font-family);
        transform: translateY(-15px);
      }
      p {
        font-size: var(--small-parahraph);
      }
    }
  }

  .greenline-content {
    display: flex;
    width: 100%;
    padding-inline: 7%;
    margin-block: 5%;

    @media screen and (max-width: 1359px) {
      margin-top: 4rem;
    }

    h2 {
      font-size: var(--medium-text-size);
      font-weight: 300;
      position: relative;

      ::before {
        position: absolute;
        content: '';
        top: -20px;
        left: 10%;
        width: 70%;
        height: 8px;
        background-color: var(--main-green);
      }

      ::after {
        position: absolute;
        content: '';
        bottom: -20px;
        right: 5%;
        width: 70%;
        height: 8px;
        background-color: var(--main-green);
      }
    }
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    padding-inline: 7%;
    margin-top: 5rem;

    @media screen and (min-width: 1360px) and (max-width: 1600px) {
      padding: 0;
    }

    .inner-cards-container {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      justify-content: center;
      @media screen and (max-width: 1359px) {
        width: 100%;
      }
    }

    .arrow-container {
      width: 10%;
    }

    .subtitle {
      display: flex;
      flex-direction: column;
      width: 90%;

      h1 {
        font-size: var(--title-font-size);
        font-family: var(--title-font-family);
        transform: translateY(-15px);
      }
      p {
        font-size: var(--small-parahraph);
      }
    }
  }
`;
