import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import bulletIcon from '../assets/icons/bullet-icon.svg';
import arrow from '../assets/icons/dot-arrow.png';

const HowDoesItWork = () => {
  const steps = [
    {
      id: 1,
      title: 'Consultation',
      description:
        'The process begins with a preliminary consultation, where we measure the exact demand and discuss the source of funds in advance.',
      show: true
    },

    {
      id: 2,
      title: 'KYC',
      description:
        'If both parties accept the framework of the cooperation, a KYC (personal identification) will be carried out.',
      show: false
    },

    {
      id: 3,
      title: 'Contract',
      description:
        'With accurate knowledge of the relevant details, we conclude the contract.',
      show: false
    },

    {
      id: 4,
      title: 'Data sharing',
      description:
        'We ask for all the available data (wallet addresses, etc.).',
      show: false
    },

    {
      id: 5,
      title: 'Analysis',
      description:
        'Another consultation will be held to discuss the findings of the research.',
      show: false
    },

    {
      id: 6,
      title: 'Lambda documentation',
      description:
        'After the finalization of the report, we prepare the documentation and hand it over to the client.',
      show: false
    },

    {
      id: 7,
      title: 'Tax advisory',
      description:
        'Additionally, if required by the client, we also provide assistance related to Hungarian taxation.',
      show: false
    }
  ];

  const [selectedStep, setSelectedStep] = useState(steps[0]);

  const isDesktop = window.innerWidth >= 1360;

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  const [data, setData] = useState(steps);

  const updateState = index => {
    const newState = data.map(obj => {
      if (obj.id === index + 1) {
        if (obj.show === false) {
          return { ...obj, show: true };
        } else if (obj.show === true) return { ...obj, show: false };
      }
      return { ...obj, show: false };
    });
    setData(newState);
  };

  return (
    <Wrapper>
      <div className='inner-container how' id='how'>
        <div className='section-container'>
          <div className='arrow'>
            <img src={arrow} alt='dot arrow' />
          </div>
          <div className='subtitle'>
            <h1 className='semi-bold'>How does it work?</h1>
            <p>
              The Lambda method is a uniquely complex service and in-depth
              analysis focusing on crypto revenue review.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <div className='toggle-section'>
            <ol className='titles'>
              {steps.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => setSelectedStep(item)}
                    className={
                      'semi-bold ' +
                      (selectedStep.title === item.title
                        ? 'selected'
                        : 'unselected')
                    }>
                    {item.title}

                    <div className='selector'>
                      <img src={bulletIcon} alt='selector' />
                      <hr />
                    </div>
                  </li>
                );
              })}
            </ol>
            <div className='descriptions'>
              <p
                style={{
                  marginTop: `${
                    steps.findIndex(step => {
                      return step.title === selectedStep.title;
                    }) *
                      25 +
                    steps.findIndex(step => {
                      return step.title === selectedStep.title;
                    }) *
                      ((1920 - Math.min(dimensions.width, 1920)) / 560) *
                      5
                  }px`
                }}>
                {selectedStep.description}
              </p>
            </div>
          </div>
        ) : (
          <div className='questions-container'>
            <div className='questions-inner-container'>
              <section className='questions'>
                {data.map((szakterulet, index) => {
                  return (
                    <article
                      key={index}
                      className={
                        'question ' +
                        (index !== data.length - 1 ? 'upper-question' : '')
                      }>
                      <header>
                        <h4>{data[index].title}</h4>
                        <button
                          className='btn'
                          aria-label='toggle button'
                          onClick={() => {
                            updateState(index);
                          }}>
                          {szakterulet.show ? (
                            <AiFillCaretUp />
                          ) : (
                            <AiFillCaretDown />
                          )}
                        </button>
                      </header>
                      <AnimatePresence>
                        {szakterulet.show && (
                          <motion.p
                            initial={{
                              height: 0,
                              opacity: 0,
                              margin: 0
                            }}
                            animate={{
                              height: 'fit-content',
                              opacity: 1,
                              display: 'flex',
                              marginTop: '2rem',
                              marginBottom: '1rem'
                            }}
                            exit={{
                              height: 0,
                              opacity: 0,
                              marginTop: 0,
                              marginBottom: 0
                            }}
                            transition={{
                              duration: 0.2,
                              ease: 'easeInOut',
                              times: [0, 0.2, 0.5, 0.8, 1]
                            }}>
                            {data[index].description}
                          </motion.p>
                        )}
                      </AnimatePresence>
                    </article>
                  );
                })}
              </section>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default HowDoesItWork;

const Wrapper = styled.div`
  width: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;

  @media screen and (max-width: 1359px) {
    padding-top: 0;
  }

  .inner-container {
    scroll-margin-top: 10rem;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
  }

  .section-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-inline: 7%;

    @media screen and (max-width: 1359px) {
      flex-direction: column;
    }

    .arrow-container {
      width: 10%;
    }

    .subtitle {
      display: flex;
      flex-direction: column;
      width: 90%;

      @media screen and (max-width: 1359px) {
        margin-top: 2rem;
        width: 100%;
      }

      h1 {
        font-size: var(--title-font-size);
        font-family: var(--title-font-family);
        transform: translateY(-15px);
      }
      p {
        font-size: var(--small-parahraph);
      }
    }
  }

  .toggle-section {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-inline: 7%;
    width: 100%;
    margin-top: 5rem;

    .titles {
      width: 50%;
      counter-reset: section;
      li {
        display: flex;
        font-size: calc(var(--text-font-size) + 0.5rem);
        font-family: var(--title-font-family);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        height: 50px;
        transition: all 0.3s;

        ::before {
          counter-increment: section;
          content: counter(section) '. ';
          font-variant-numeric: tabular-nums;
          margin-right: 0.5rem;
        }

        :hover {
          cursor: pointer;
        }

        &.selected {
          color: var(--main-btn-color);
          transform: translateX(-1.5rem);
          width: calc(100% + 1.5rem);
          .selector {
            opacity: 1;
          }
        }

        &.unselected {
          .selector {
            opacity: 0;
            width: 0;
            flex-grow: unset;
          }
        }
      }

      .selector {
        display: inline-flex;
        align-items: center;
        flex-grow: 1;
        padding-left: 3rem;
        transition: all 0.3s;
        hr {
          width: calc(100% + 3rem);
          display: block;
          height: 4px;
          border: 0;
          border-top: 5px solid var(--main-green);
          margin: 15px 0;
          padding: 0;
        }
        img {
          height: 18px;
        }
      }
    }

    .descriptions {
      display: flex;
      justify-content: flex-end;
      width: fit-content;
      p {
        padding: 3rem;
        width: 35rem;
        border: 5px solid var(--main-green);
        border-radius: 15px;
        font-size: var(--small-parahraph);
        display: flex;
        height: fit-content;
        transition: all 0.3s;
      }
    }
  }

  .question {
    padding: 1rem 0;
    margin-bottom: 1rem;
  }

  .upper-question {
    border-bottom: 1px solid #00e383;
  }

  .question .btn {
    border-radius: 35px;
    width: 35px;
    height: 35px;
    z-index: 10;
    border: none;
    min-width: 0;
    width: 50px;
    padding: 0;
    background-color: var(--white);
  }

  .question .btn:hover {
    cursor: pointer;
  }

  .question svg {
    transform: translateX(0.5px) translateY(1px);
    width: 15px;
    height: 15px;
    filter: invert(17%) sepia(98%) saturate(524%) hue-rotate(166deg)
      brightness(97%) contrast(92%);
  }

  .question header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .question header h4 {
    margin-bottom: 0;
    text-transform: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: clamp(2rem, 1.5vw, 8.3rem);
    line-height: clamp(2.5rem, 2vw, 8.3rem);
    letter-spacing: 0.02em;
    color: var(--black);
  }

  .question p {
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: var(--black);
    font-family: Poppins;
    font-style: normal;
    font-size: clamp(1.1rem, 0.7vw, 20rem);
    line-height: clamp(1.5rem, 1.5vw, 20.5rem);
    letter-spacing: 0.02em;
    font-weight: 300;
  }

  .questions-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 3em;
    height: fit-content;
    margin-bottom: 3rem;
    margin-top: 1rem;
  }

  .questions-inner-container {
    width: 80%;
  }

  .questions-container img {
    height: 10em;
  }

  .questions article:not(.upper-question) {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .questions-container {
    padding-top: 2em;
    height: fit-content;
  }

  .questions-inner-container {
    width: 100%;
  }

  .question header h4 {
    font-size: var(--title-font-size);
  }

  .questions-container {
    padding-top: 0;
  }

  .question p {
    font-size: 16px;
  }
`;
