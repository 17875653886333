import styled from 'styled-components'

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <h1>Privacy Policy</h1>
      <p className='updated'>Last updated: August 1, 2022</p>
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>
      <div className='interpretation'>
        <h2>Interpretation and Definitions</h2>
        <strong>Interpretation</strong>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <strong>Definitions</strong>
        <p>For the purposes of this Privacy Policy:</p>
        <p>
          <span>Account</span> means a unique account created for You to access
          our Service or parts of our Service.
        </p>
        <p>
          <span>Company</span> (referred to as either "the Company", "We", "Us"
          or "Our" in this Agreement) refers to Kripteus Ltd., HU-1117 Budapest,
          Hauszmann Alajos u. 3/b. For the purpose of the GDPR, the Company is
          the Data Controller.
        </p>
        <p>
          <span>Cookies</span> are small files that are placed on Your computer,
          mobile device or any other device by a website, containing the details
          of Your browsing history on that website among its many uses.
        </p>
        <p>
          <span>Country</span> refers to: Hungary
        </p>
        <p>
          <span>Data Controller,</span> for the purposes of the GDPR (General
          Data Protection Regulation), refers to the Company as the legal person
          which alone or jointly with others determines the purposes and means
          of the processing of Personal Data.
        </p>
        <p>
          <span>Device</span> means any device that can access the Service such
          as a computer, a cellphone or a digital tablet.
        </p>
        <p>
          <span>Personal Data</span> is any information that relates to an
          identified or identifiable individual. For the purposes for GDPR,
          Personal Data means any information relating to You such as a name, an
          identification number, location data, online identifier or to one or
          more factors specific to the physical, physiological, genetic, mental,
          economic, cultural or social identity.
        </p>
        <p>
          <span>Service</span> refers to the Website.
        </p>
        <p>
          <span>Service Provider</span> means any natural or legal person who
          processes the data on behalf of the Company. It refers to third-party
          companies or individuals employed by the Company to facilitate the
          Service, to provide the Service on behalf of the Company, to perform
          services related to the Service or to assist the Company in analyzing
          how the Service is used. For the purpose of the GDPR, Service
          Providers are considered Data Processors.
        </p>
        <p>
          <span>Usage Data</span> refers to data collected automatically, either
          generated by the use of the Service or from the Service infrastructure
          itself (for example, the duration of a page visit).
        </p>
        <p>
          <span>Website</span> refers to Kripteus Lambda Program,
          <a href='https://kripteus.com'> https://kripteus.com</a>
        </p>
        <p>
          <span>You</span> means the individual accessing or using the Service,
          or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable.
        </p>
        <p>
          Under GDPR (General Data Protection Regulation), You can be referred
          to as the Data Subject or as the User as you are the individual using
          the Service.
        </p>
      </div>
      <div className='collectinf'>
        <h2>Collecting and Using Your Personal Data</h2>
        <h4>Types of Data Collected</h4>
        <strong>Personal Data</strong>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Usage Data</li>
        </ul>
        <strong>Usage Data</strong>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>
          Usage Data may include information such as the time and date of Your
          visit, the time spent on the pages, and other diagnostic data.
        </p>
        <p>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>
        <span>Tracking Technologies and Cookies</span>
        <p>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </p>
        <ul>
          <li>
            <span>Cookies or Browser Cookies.</span> A cookie is a small file
            placed on Your Device. You can instruct Your browser to refuse all
            Cookies or to indicate when a Cookie is being sent. However, if You
            do not accept Cookies, You may not be able to use some parts of our
            Service. Unless you have adjusted Your browser setting so that it
            will refuse Cookies, our Service may use Cookies.
          </li>
          <li>
            <span>Flash Cookies.</span> Certain features of our Service may use
            local stored objects (or Flash Cookies) to collect and store
            information about Your preferences or Your activity on our Service.
            Flash Cookies are not managed by the same browser settings as those
            used for Browser Cookies. For more information on how You can delete
            Flash Cookies, please read "Where can I change the settings for
            disabling, or deleting local shared objects?" available at
            <a href='https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_'>
              https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
            </a>
          </li>
          <li>
            <span>Web Beacons.</span> Certain sections of our Service and our
            emails may contain small electronic files known as web beacons (also
            referred to as clear gifs, pixel tags, and single-pixel gifs) that
            permit the Company, for example, to count users who have visited
            those pages or opened an email and for other related website
            statistics (for example, recording the popularity of a certain
            section and verifying system and server integrity).
          </li>
        </ul>
        <p>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on Your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close Your web
          browser. You can learn more about cookies here: All About Cookies by
          TermsFeed.
        </p>
        <p>
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </p>
        <div className='table'>
          <table>
            <thead>
              <tr>
                <th>Cookie</th>
                <th>Type</th>
                <th>Administered by:</th>
                <th>Purpose:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Necessary / Essential Cookies</td>
                <td>Session Cookies</td>
                <td>Us</td>
                <td>
                  {' '}
                  These Cookies are essential to provide You with services
                  available through the Website and to enable You to use some of
                  its features. They help to authenticate users and prevent
                  fraudulent use of user accounts. Without these Cookies, the
                  services that You have asked for cannot be provided, and We
                  only use these Cookies to provide You with those services.
                </td>
              </tr>
              <tr>
                <td>Cookies Policy / Notice Acceptance Cookies</td>
                <td>Persistent Cookies</td>
                <td>Us</td>
                <td>
                  These Cookies identify if users have accepted the use of
                  cookies on the Website.
                </td>
              </tr>
              <tr>
                <td>Functionality Cookies</td>
                <td>Persistent Cookies</td>
                <td>Us</td>
                <td>
                  These Cookies allow us to remember choices You make when You
                  use the Website, such as remembering your login details or
                  language preference. The purpose of these Cookies is to
                  provide You with a more personal experience and to avoid You
                  having to re-enter your preferences every time You use the
                  Website.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          For more information about the cookies we use and your choices
          regarding cookies, please visit the Cookies section of our Privacy
          Policy.
        </p>
        <h4>Use of Your Personal Data</h4>
        <p>The Company may use Personal Data for the following purposes:</p>
        <p>
          <span>To provide and maintain our Service,</span> including to monitor
          the usage of our Service.
        </p>
        <p>
          <span>To manage Your Account:</span> to manage Your registration as a
          user of the Service. The Personal Data You provide can give You access
          to different functionalities of the Service that are available to You
          as a registered user.
        </p>
        <p>
          <span>For the performance of a contract:</span> the development,
          compliance and undertaking of the purchase contract for the products,
          items or services You have purchased or of any other contract with Us
          through the Service.
        </p>
        <p>
          <span>To contact You:</span> To contact You by email, or other
          equivalent forms of electronic communication, such as a mobile
          application's push notifications regarding updates or informative
          communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable
          for their implementation.
        </p>
        <p>
          <span>To provide You:</span> with news, special offers and general
          information about other goods, services and events which we offer that
          are similar to those that you have already purchased or enquired about
          unless You have opted not to receive such information.
        </p>
        <p>
          <span> To manage Your requests:</span> To attend and manage Your
          requests to Us.
        </p>
        <p>
          <span>For business transfers:</span> We may use Your information to
          evaluate or conduct a merger, divestiture, restructuring,
          reorganization, dissolution, or other sale or transfer of some or all
          of Our assets, whether as a going concern or as part of bankruptcy,
          liquidation, or similar proceeding, in which Personal Data held by Us
          about our Service users is among the assets transferred.
        </p>
        <p>
          <span>For other purposes:</span> We may use Your information for other
          purposes, such as data analysis, identifying usage trends, determining
          the effectiveness of our promotional campaigns and to evaluate and
          improve our Service, products, services, marketing and your
          experience.
        </p>
        <p>
          We may share Your personal information in the following situations:
        </p>
        <p>
          <span>With Your consent:</span> We may disclose Your personal
          information for any other purpose with Your consent.
        </p>
        <h4>Retention of Your Personal Data</h4>
        <p>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is retained for a shorter period of time – 12 hrs
          -, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>
        <h4>Transfer of Your Personal Data</h4>
        <p>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </p>
        <p>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
        <h4>Disclosure of Your Personal Data</h4>
        <strong>Business Transaction</strong>
        <p>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <strong>Law enforcement</strong>
        <p>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <strong>Other legal requirements</strong>
        <p>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>
        <h4>Security of Your Personal Data</h4>
        <p>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
      </div>
      <div>
        <h2>GDPR Privacy</h2>
        <h4>Legal Basis for Processing Personal Data under GDPR</h4>
        <p>We may process Personal Data under the following conditions:</p>
        <ul>
          <li>
            <span>Consent:</span> You have given Your consent for processing
            Personal Data for one or more specific purposes.
          </li>
          <li>
            <span>Performance of a contract:</span> Provision of Personal Data
            is necessary for the performance of an agreement with You and/or for
            any pre-contractual obligations thereof.
          </li>
          <li>
            <span>Legal obligations:</span> Processing Personal Data is
            necessary for compliance with a legal obligation to which the
            Company is subject.
          </li>
          <li>
            <span>Vital interests:</span> Processing Personal Data is necessary
            in order to protect Your vital interests or of another natural
            person.
          </li>
          <li>
            <span>Public interests:</span> Processing Personal Data is related
            to a task that is carried out in the public interest or in the
            exercise of official authority vested in the Company.
          </li>
          <li>
            <span>Legitimate interests:</span> Processing Personal Data is
            necessary for the purposes of the legitimate interests pursued by
            the Company.
          </li>
          <p>
            {' '}
            In any case, the Company will gladly help to clarify the specific
            legal basis that applies to the processing, and in particular
            whether the provision of Personal Data is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract.
          </p>
        </ul>
        <h4>Your Rights under the GDPR</h4>
        <p>
          The Company undertakes to respect the confidentiality of Your Personal
          Data and to guarantee You can exercise Your rights.
        </p>
        <ul>
          <li>
            <span>Request access to Your Personal Data. </span>The right to
            access, update or delete the information We have on You. Whenever
            made possible, you can access, update or request deletion of Your
            Personal Data directly within Your account settings section. If you
            are unable to perform these actions yourself, please contact Us to
            assist You. This also enables You to receive a copy of the Personal
            Data We hold about You.
          </li>
          <li>
            <span>
              Request correction of the Personal Data that We hold about You.{' '}
            </span>
            You have the right to have any incomplete or inaccurate information
            We hold about You corrected.
          </li>
          <li>
            <span>Object to processing of Your Personal Data. </span>This right
            exists where We are relying on a legitimate interest as the legal
            basis for Our processing and there is something about Your
            particular situation, which makes You want to object to our
            processing of Your Personal Data on this ground. You also have the
            right to object where We are processing Your Personal Data for
            direct marketing purposes.
          </li>
          <li>
            <span>Request erasure of Your Personal Data. </span>You have the
            right to ask Us to delete or remove Personal Data when there is no
            good reason for Us to continue processing it.
          </li>
          <li>
            <span>Request the transfer of Your Personal Data. </span>We will
            provide to You, or to a third-party You have chosen, Your Personal
            Data in a structured, commonly used, machine-readable format. Please
            note that this right only applies to automated information which You
            initially provided consent for Us to use or where We used the
            information to perform a contract with You.
          </li>
          <li>
            <span>Withdraw Your Consent.</span> You have the right to withdraw
            Your consent on using your Personal Data. If You withdraw Your
            consent, We may not be able to provide You with access to certain
            specific functionalities of the Service.
          </li>
        </ul>
        <h4>Exercising of Your GDPR Data Protection Rights</h4>
        <p>
          You may exercise Your rights of access, rectification, cancellation
          and opposition by contacting Us. Please note that we may ask You to
          verify Your identity before responding to such requests. If You make a
          request, We will try our best to respond to You as soon as possible.
        </p>
        <p>
          You have the right to complain to a Data Protection Authority about
          Our collection and use of Your Personal Data. For more information, if
          You are in the European Economic Area (EEA), please contact Your local
          data protection authority in the EEA.
        </p>
      </div>
      <div>
        <h2>Children's Privacy</h2>
        <p>
          Our Service does not address anyone under the age of 18. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 18. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 18 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </p>
        <p>
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </p>
      </div>
      <div>
        <h2>Links to other Websites</h2>
        <p>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
      </div>
      <div>
        <h2>Changes to this Privacy Policy</h2>
        <p>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
      </div>
      <div>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:{' '}
        </p>
        <p>
          By email: <a href='mailto:info@kripteus.hu'>info@kripteus.hu</a>
        </p>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-top: 12rem;

  h1 {
    font-size: var(--hero-font-size);
    padding: 2rem 0;
  }

  h2 {
    font-size: clamp(1rem, 0.615rem + 2vw, 2.75rem);
    margin: 1rem 0;
    text-decoration: underline;
  }

  h4 {
    font-size: clamp(1rem, 0.615rem + 0.8vw, 2rem);
    margin: 1rem 0;
  }

  .updated {
    font-style: italic;
  }

  strong {
    font-size: 1.3rem;
    margin: 0.8rem 0;
    display: block;
  }

  p {
    line-height: 1.7;
  }

  span {
    font-weight: bold;
    line-height: 1.7rem;
  }

  ul {
    margin-left: 3rem;
  }

  table,
  th,
  td {
    border: 1px solid #666;
  }

  td,
  th {
    padding: 1rem;
  }

  table {
    margin: 1rem 0;
    text-align: center;
  }

  .table {
    overflow: scroll;
  }

  a {
    word-wrap: break-word;
  }
`

export default PrivacyPolicy
